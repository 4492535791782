import React, { useRef, useState, useEffect } from "react";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
export { FileWithPath } from "file-selector";
import { makeStyles, createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { getUserProfile } from "../../../../helpers/sso-helper";
import { filterUserProfileInfo } from "../../../../helpers/documentHelper";
import ProgressBar from "react-bootstrap/ProgressBar";
import toastr from "toastr";
import $ from "jquery";
import axios from "axios";
import { GlobalWorkerOptions } from "pdfjs-dist";

import OneGateway from "../oneGateway/OneGateway";

import styles from "./MainPage.module.css";

interface LoggingAction {
  state: "translate" | "rewrite" | "summary" | "ideas" | "search" | "advanced" | "";
  payload: {
    functionNm: any;
    logTokenUsage: string;
  } | null;
}
type LoggingState = Exclude<LoggingAction["state"], "">;

const loggingState: Record<LoggingState, Capitalize<LoggingState>> = {
  advanced: "Advanced",
  ideas: "Ideas",
  rewrite: "Rewrite",
  search: "Search",
  summary: "Summary",
  translate: "Translate",
};

const RETRY_COUNT = 3;

const MainPage = () => {
  const [selectedFiles, setSelectedFiles] = useState<Array<FileObject>>([]);
  const [progress, setProgress] = useState<number>(0);
  const [chunkList, setChunkList] = useState<{ chunk: Blob }[]>([]);
  const [output, setOutput] = useState<String>("");
  const [disabledUpload, setDisabledUpload] = useState(true);
  const [totalPercentage, setTotalPercentage] = useState(0);
  var [nowPercentage, setNowPercentage] = useState(0);
  var [pdfTotalPage, setPdfTotalPage] = useState(0);
  let [userProfile, setUserProfile] = useState([]);
  let [fileSelectByDrop, setFileSelectedByDrop] = useState(true);
  const [loggingAction, setLoggingAction] = useState<LoggingAction>({
    state: "",
    payload: null,
  });

  const retryGetUserProfile = useRef(RETRY_COUNT); // retry count

  var percentageInterval;
  var getFileStatusInterval;

  const pdfjs = require("pdfjs-dist");

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: "toast-bottom-right",
    preventDuplicates: false,
  };

  function randomNumberInRange(min: number, max: number) {
    return Math.round(min + Math.random() * (max - min));
  }

  useEffect(() => {
    setTotalPercentage(randomNumberInRange(91, 98));
  }, []);

  const startProcessPDF = (fileSize: number) => {
    var fileSizeAndWaitTimeProportion = 143;
    var maxPercentage = 100;
    var bufferTime = 15000;
    var totalTime = fileSize / fileSizeAndWaitTimeProportion;
    totalTime = totalTime + bufferTime;
    var intervalTime = totalTime / maxPercentage;
    percentageInterval = setInterval(() => {
      if (nowPercentage < totalPercentage) {
        nowPercentage += 1;
        setNowPercentage(nowPercentage);
      } else {
        clearInterval(percentageInterval);
      }
    }, intervalTime);
  };

  const handleChange = (files: FileObject[]) => {
    // setDisabledUpload(true);
    setSelectedFiles([]);
    setProgress(0);
    setNowPercentage(0);

    var check = true;

    if (files[0].file.type != "application/pdf") {
      setOutput("Sorry, the file type you uploaded is not supported. Only PDF files accepted.");
      check = false;
    }

    if (check) {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const arrayBuffer = fileReader.result;
        pdfjs.getDocument(arrayBuffer).promise.then(function (doc) {
          var totalPages = doc.numPages;
          setPdfTotalPage(totalPages);
          if (totalPages > 200) {
            setOutput(
              "Sorry, this PDF is too large that exceed the current limit of 200 pages. If you have a strong demand for large PDF processing, please contact LFAICopilot@lifung.com"
            );
          } else {
            setSelectedFiles(files);
            // setDisabledUpload(false);
          }
        });
      };

      fileReader.readAsArrayBuffer(files[0].file);
      GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      startUpload();
    }
  }, [selectedFiles]);

  const splitFile = (file: File, size = 500000) => {
    const fileChunkList = [];
    let curChunkIndex = 0;
    while (curChunkIndex <= file.size) {
      const chunk = file.slice(curChunkIndex, curChunkIndex + size);
      fileChunkList.push({ chunk: chunk });
      curChunkIndex += size;
    }
    return fileChunkList;
  };

  const onUploadProgress = (progressEvent) => {
    setOutput(`Uploading...`);
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent < 100) {
      setProgress(percent);
    } else {
      setProgress(100);
      setOutput(
        "Your PDF is being processed:\nYou can wait here until it ready.\nOr you can safely leave here. We will send you an email with the access link once the file is ready."
      );

      setNowPercentage(1);
      startProcessPDF(selectedFiles[0].file.size);
    }
  };

  const startUpload = () => {
    // setDisabledUpload(true);
    setProgress(0);
    setNowPercentage(0);

    selectedFiles.forEach(function (file) {
      var formData = new FormData();
      formData.append("file", file.file);
      formData.append("email", userProfile[1]);
      formData.append("channel", "via Addin");
      formData.append("file_pagecount", pdfTotalPage + "");
      formData.append("file_size", file.file.size + "");
      axios
        .post("https://lfaihub.lfapps.net/api/AzureLA-AskPDF-fileupload/v1/", formData, {
          headers: {
            "Ocp-Apim-Subscription-Key": "7fb4886b68fd4a5ca0a4f687facfa377",
            "Content-Type": "multipart/form-data",
            apiKey: "7Fg77qQclAJhY7y2Ph%2BKwnW0YDztLkLHMVV0I3bnFwg%3D;",
          },
          onUploadProgress,
        })
        .then((response) => {
          if (response.status == 401) {
            setOutput(response.data.error);
          }

          if (response.status == 409) {
            setOutput(response.data.Conflict);
          }

          if (response.status == 502) {
            setOutput(response.data.error);
          }

          if (response.status == 200) {
            getFileStatus(response.data.fileuid);
          }
        })
        .catch((error) => {
          setOutput(error);
        });
    });
  };

  const getFileStatus = (fileuid: String) => {
    let topText =
      "Your PDF is being processed.<br/>" +
      "You can either wait here until it’s ready or safely leave this page. Once the file is ready, we will send you an email with the access link.<div class='divider'></div>" +
      "<b>Status: </b>";
    getFileStatusInterval = setInterval(() => {
      axios
        .get("https://lfaihub.lfapps.net/api/app-AskPDF-filestatus/v1/?uniqueid=" + fileuid, {
          headers: {
            "Ocp-Apim-Subscription-Key": "7fb4886b68fd4a5ca0a4f687facfa377",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == "Active") {
              $("#outputBox").html(topText + response.data.statuslog);
            }
            if (response.data.status == "Failed") {
              $("#outputBox").html(topText + response.data.statuslog);
            }
            if (response.data.status == "Completed") {
              clearInterval(getFileStatusInterval);
              clearInterval(percentageInterval);
              setNowPercentage(100);
              $("#outputBox").html(
                'You can chat with your PDF now:\n<a href="' +
                  response.data.url +
                  '" target="_blank">' +
                  response.data.url +
                  "</a>"
              );
            }
          }
        })
        .catch((error) => {
          setOutput(error);
        });
    }, 2000);
  };

  const splitAction = () => {
    selectedFiles.forEach(function (file) {
      setChunkList(splitFile(file.file));
    });
  };

  const sso = () => {
    getUserProfile(writeDataToOfficeDocument);
  };

  useEffect(() => {
    $(".dropzone_click").on("click", function () {
      setFileSelectedByDrop(false);
    });
  });

  function writeDataToOfficeDocument(result) {
    let data = [];
    let userProfileInfo = filterUserProfileInfo(result);

    for (let i = 0; i < userProfileInfo.length; i++) {
      if (userProfileInfo[i] !== null) {
        data.push(userProfileInfo[i]);
      }
    }
    setUserProfile(userProfileInfo);

    let userInfo = "";
    for (let i = 0; i < data.length; i++) {
      userInfo += data[i] + "\n";
    }
    // Office.context.mailbox.item.body.setSelectedDataAsync(userInfo, { coercionType: Office.CoercionType.Html });
    // document.getElementById("tempoutput").innerHTML = userInfo;
    setOutput("Good Day!\n" + data[0]);
    // document.getElementById("sso-username").innerHTML = data[0];
  }

  useEffect(() => {
    sso();
  }, []);

  async function checkInput() {
    var check = true;
    try {
      const clipboard = await navigator.clipboard.readText();
      if ($("#inputBox").val() == "") {
        $("#inputBox").val(clipboard);
      }
    } catch (e) {}

    if ($("#inputBox").val() == "") {
      check = false;
      toastr.error("Input empty.");
    }
    return check;
  }

  function logging(
    functionGroup: string,
    FunctionName: any,
    Log_tokenusage: string,
    userProfile: any,
    logOtherInfo?: string
  ) {
    var body = {
      App_name: "LF AI Assistant",
      App_group: "AI WebApps",
      App_ownerteam: "CropApps",
      App_deployment: "PRD",
      Function_group: functionGroup,
      Function_name: FunctionName,
      User_id: userProfile[2],
      User_name: userProfile[0],
      User_input: $("#inputBox").val(),
      Log_tokenusage: Log_tokenusage,
      Log_OtherInfo: logOtherInfo || undefined,
    };

    axios({
      method: "post",
      url: "https://lfaihub.lfapps.net/api/util-Logging/v1",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
      },
      data: JSON.stringify(body),
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() =>
        setLoggingAction({
          state: "",
          payload: null,
        })
      );
  }

  const { payload, state } = loggingAction;
  useEffect(() => {
    // it will trigger when user click any button that invoke the logging function
    // User profile is updated
    if (payload && state && userProfile?.length) {
      console.log(`user profile ${userProfile}`);
      logging(loggingState[state], payload.functionNm, payload.logTokenUsage, userProfile);
      retryGetUserProfile.current = RETRY_COUNT;
    }

    if (payload && state && !userProfile?.length) {
      // Retry times <= 0 & User profile is not existed
      if (retryGetUserProfile.current <= 0) {
        logging(loggingState[state], payload.functionNm, payload.logTokenUsage, [], "User profile was not provided");
        retryGetUserProfile.current = RETRY_COUNT;
      }
      // User profile is not existed & Retry times > 0
      else {
        retryGetUserProfile.current--;
      }
    }
  }, [userProfile, payload, state]);

  const translate = () => {
    var lang = $("#sel_language option:selected").val();
    checkInput().then((result) => {
      if (result) {
        $("#outputBox").text("Processing with the Inputbox contents. \nPlease wait a moment...");
        var body = {
          LFAIinput: "Please tranlate below into " + lang + " like a native speaker: " + $("#inputBox").val(),
        };
        axios({
          method: "post",
          url: "https://lfaihub.lfapps.net/api/common-LF-GPT-Oneshot/v1",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
          },
          data: JSON.stringify(body),
        })
          .then((response) => {
            setLoggingAction({
              state: "translate",
              payload: {
                functionNm: lang,
                logTokenUsage: response.data.LFAITokenTotal,
              },
            });
            setOutput(response.data.LFAIoutput);
            $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const rewrite = () => {
    var rewrite = $("#sel_rewrite option:selected").text();
    if (rewrite == "Typo Correction") {
      var body = {
        LFAIinput: "Please correct the typo if any for below: " + $("#inputBox").val(),
      };
    }
    if (rewrite == "Native Speaker") {
      var body = {
        LFAIinput:
          "Please take the content between ##quote## as the input, and rewrite the input as of a native speaker ##quote## " +
          $("#inputBox").val() +
          " ##quote##",
      };
    }
    if (rewrite == "Shorter" || rewrite == "Longer") {
      var body = {
        LFAIinput: "Please draft a email about the topic, in a " + rewrite + " paragrah: " + $("#inputBox").val(),
      };
    }
    checkInput().then((result) => {
      if (result) {
        $("#outputBox").text("Processing with the Inputbox contents. \nPlease wait a moment...");
        axios({
          method: "post",
          url: "https://lfaihub.lfapps.net/api/common-LF-GPT-Oneshot/v1",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
          },
          data: JSON.stringify(body),
        })
          .then((response) => {
            setLoggingAction({
              state: "rewrite",
              payload: {
                functionNm: rewrite,
                logTokenUsage: response.data.LFAITokenTotal,
              },
            });
            setOutput(response.data.LFAIoutput);
            $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const summary = () => {
    var summary = $("#sel_summary option:selected").text();
    checkInput().then((result) => {
      if (result) {
        $("#outputBox").text("Processing with the Inputbox contents. \nPlease wait a moment...");
        var body = {
          LFAIinput: "Please summary below into clear " + summary + " form: " + $("#inputBox").val(),
        };
        axios({
          method: "post",
          url: "https://lfaihub.lfapps.net/api/common-LF-GPT-Oneshot/v1",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
          },
          data: JSON.stringify(body),
        })
          .then((response) => {
            setLoggingAction({
              state: "summary",
              payload: {
                functionNm: summary,
                logTokenUsage: response.data.LFAITokenTotal,
              },
            });
            setOutput(response.data.LFAIoutput);
            $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const ideas = () => {
    var ideas = $("#sel_ideas option:selected").text();
    checkInput().then((result) => {
      if (result) {
        $("#outputBox").text("Processing with the Inputbox contents. \nPlease wait a moment...");
        if (ideas == "Brainstorm") {
          var body = {
            LFAIinput: "Please give some brainstorm ideas around the topic: " + $("#inputBox").val(),
          };
        }
        if (ideas == "Breakdown") {
          var body = {
            LFAIinput: "Please provide some actionable breakdown sub-tasks around the topic: " + $("#inputBox").val(),
          };
        }
        if (ideas == "Agenda") {
          var body = {
            LFAIinput: "Please draft a agenda for a discussion around the topic: " + $("#inputBox").val(),
          };
        }
        axios({
          method: "post",
          url: "https://lfaihub.lfapps.net/api/common-LF-GPT-Oneshot/v1",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
          },
          data: JSON.stringify(body),
        })
          .then((response) => {
            setLoggingAction({
              state: "ideas",
              payload: {
                functionNm: ideas,
                logTokenUsage: response.data.LFAITokenTotal,
              },
            });
            setOutput(response.data.LFAIoutput);
            $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const search = () => {
    var selSearchval = $("#sel_search option:selected").val();
    var selSearchtext = $("#sel_search option:selected").text();
    checkInput().then((result) => {
      if (result) {
        $("#outputBox").text("Processing with the Inputbox contents. \nPlease wait a moment...");
        var body = {
          LFAIinput: $("#inputBox").val(),
        };

        if (selSearchval == "BingSearch") {
          axios({
            method: "post",
            url: "https://lfaihub.lfapps.net/api/common-LF-GPT-iBing/v1",
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
              "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
            },
            data: JSON.stringify(body),
          })
            .then((response) => {
              setLoggingAction({
                state: "search",
                payload: {
                  functionNm: selSearchtext,
                  logTokenUsage: response.data.LFAITokenTotal,
                },
              });
              setOutput(response.data.LFAIoutput);
              $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }

      if (selSearchval == "ITServiceDeskKB") {
        axios({
          method: "post",
          url: "https://lfaihub.lfapps.net/api/kb-ITSD/v1/",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
          },
          data: JSON.stringify(body),
        })
          .then((response) => {
            setLoggingAction({
              state: "search",
              payload: {
                functionNm: selSearchtext,
                logTokenUsage: response.data.LFAITokenTotal,
              },
            });
            setOutput(response.data.LFAIoutput);
            $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const GPTEditor = () => {
    var origin_output = $("#outputBox").text();
    checkInput().then((result) => {
      if (result) {
        $("#outputBox").text("Processing with your request. \nPlease wait a moment...");
        var body = {
          messages: [
            {
              role: "system",
              content: "You are a helpful AI writer assistant.",
            },
            {
              role: "assistant",
              content: origin_output,
            },
            {
              role: "user",
              content: "Please help modify the previous message with below instructions: " + $("#GPTinputBox").val(),
            },
          ],
        };
        axios({
          method: "post",
          url: "https://lfaihub.lfapps.net/api/common-AzureOpenAI-GPT-chat/v1",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
          },
          data: JSON.stringify(body),
        })
          .then((response) => {
            setLoggingAction({
              state: "advanced",
              payload: {
                functionNm: "GPT Editor",
                logTokenUsage: response.data.usage.total_tokens,
              },
            });
            setOutput(response.data.choices[0].message.content);
            $("#btn_gpt_editor").removeClass("btn_gpt_editor_disable").addClass("btn_gpt_editor_enable");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  function feedback() {
    $("#outputBox").text(
      "Feedback: \n Welcome for sharing with us any feedbacks / ideas / suggestions / use cases! \nPlease send an email to LFAICopilot@lifung.com"
    );
    if ($("#gtp_editor_container").hasClass("d-none")) {
    } else {
      $("#gtp_editor_container").addClass("d-none");
    }
    $("#btn_gpt_editor").addClass("btn_gpt_editor_disable").removeClass("btn_gpt_editor_enable");
  }

  function roadmap() {
    $("#outputBox").text(
      "Product Roadmap: \nPlanning: \n- LF AskFile HP \n- Outlook Mobile adoption\n- UI/UX improvements \n- More LF Apps integrations \n \n Release Note: \n v2.0 \n - New Feature: LF AskPDF\n- New Feature: One Gateway\n- Moved LF Insight under AI Functions\n- SSO integration\n- UI/UX improvement \n v1.3 \n - New Feature: Outlook Web support \n - UX improvment & bugfixes\nv1.2 \n - New Feature: Ideas (AI Functions)\n - New Feature: Rewrite: Typo Correction & Native Speaker \nv1.1 \n - New Feature: GPT Editor\n - UI improvements \nv1.0 \n- Basic AI functions \n- Reveal UI v1.0 \n- Reveal UI v0.2 \n- MVP v0.1"
    );
    if ($("#gtp_editor_container").hasClass("d-none")) {
    } else {
      $("#gtp_editor_container").addClass("d-none");
    }
    $("#btn_gpt_editor").addClass("btn_gpt_editor_disable").removeClass("btn_gpt_editor_enable");
  }

  function disclaimer() {
    $("#outputBox").text("Disclaimer: \nPlease validate the AI Generated Contents before making use of it.");
    if ($("#gtp_editor_container").hasClass("d-none")) {
    } else {
      $("#gtp_editor_container").addClass("d-none");
    }
    $("#btn_gpt_editor").addClass("btn_gpt_editor_disable").removeClass("btn_gpt_editor_enable");
  }

  function userguide() {
    $("#outputBox").text(
      "User Guide: \n1.	For the first time, need to Allow the clipboard access permission.\n 2.	For Outlook desktop users: To provide input, please select/highlight the target texts, Copy/CTRL-C, then click the functions. No need to Paste/CTRL-V, the add-in will read from the clipboard. \n3. For Outlook Web users, To provide input, please Copy and Paste the content into the input box, then click the functions. \n4.	Users can also edit the input text within the input box at any time, the Functions will take from the input box as final input.\n 5. The LF AI Assistant  supports Outlook Desktop/Mac/Web versions at this moment, Outlook Mobile support work in progress."
    );
    if ($("#gtp_editor_container").hasClass("d-none")) {
    } else {
      $("#gtp_editor_container").addClass("d-none");
    }
    $("#btn_gpt_editor").addClass("btn_gpt_editor_disable").removeClass("btn_gpt_editor_enable");
  }

  function copyToClipboard() {
    var str = $("#outputBox").text();
    if (str.length > 0) {
      navigator.clipboard.writeText(str).then(
        function () {
          toastr.success("Text copyed.");
        },
        function () {
          toastr.error("Copy fail.");
        }
      );
    } else {
      toastr.info("Output is empty.");
    }
  }

  function clearInput() {
    $("#inputBox").val("");
  }

  function gotoRoadmap() {
    $("#nav_about_tab").trigger("click");
    $("#btn_roadmap").trigger("click");
  }

  function gotoUserguide() {
    $("#nav_about_tab").trigger("click");
    setTimeout(function () {
      $("#btn_func_userguide").trigger("click");
    }, 20);
  }

  function gptEditorUI() {
    if ($("#btn_gpt_editor").hasClass("btn_gpt_editor_enable")) {
      if ($("#gtp_editor_container").hasClass("d-none")) {
        $("#gtp_editor_container").removeClass("d-none");
      } else {
        $("#gtp_editor_container").addClass("d-none");
      }
    }
  }

  function changeTag(event) {
    switch (event.target.id) {
      case "nav_ai_function_tab":
        $(".output-area").removeClass("d-none");
        setOutput("");
        break;
      case "nav_ask_pdf_tab":
        setSelectedFiles([]);
        setProgress(0);
        setNowPercentage(0);
        $(".output-area").removeClass("d-none");
        setOutput(
          "User Guide for LF AskPDF: \n1. Only PDF files accepted. \n2. Currently the PDF acceptance limited to 200 pages maximum, as for the pilot run peroid. If you have a strong demand for large file processing, please contact LFAICopilot@lifung,com. \n3. For each user, only one PDF can be processed at a time. If you have a PDF submitted and still being processing, please wait until it completed before uploading another one."
        );
        break;
      case "nav_one_gateway_tab":
        $(".output-area").addClass("d-none");
        setOutput("");
        break;
      case "nav_about_tab":
        $(".output-area").removeClass("d-none");
        setOutput("");
        break;
    }
  }

  return (
    <div className="ms-font-m ms-welcome ms-Fabric p-2">
      <div id="app-body" className="d-flex flex-column">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active font_12"
              id="nav_ai_function_tab"
              data-bs-toggle="tab"
              data-bs-target="#nav_ai_function"
              type="button"
              role="tab"
              aria-controls="nav_ai_function"
              aria-selected="true"
              onClick={changeTag}
            >
              AI Functions
            </button>
            {/*<button
              className="nav-link font_12"
              id="nav_ask_pdf_tab"
              data-bs-toggle="tab"
              data-bs-target="#nav_ask_pdf"
              type="button"
              role="tab"
              aria-controls="nav_ask_pdf"
              aria-selected="false"
              onClick={changeTag}
            >
              Ask PDF
            </button>*/}
            <button
              className="nav-link font_12"
              id="nav_one_gateway_tab"
              data-bs-toggle="tab"
              data-bs-target="#nav_one_gateway"
              type="button"
              role="tab"
              aria-controls="nav_one_gateway"
              aria-selected="false"
              onClick={changeTag}
            >
              One Gateway
            </button>
            <button
              className="nav-link font_12"
              id="nav_about_tab"
              data-bs-toggle="tab"
              data-bs-target="#nav_about"
              type="button"
              role="tab"
              aria-controls="nav_about"
              aria-selected="false"
              onClick={changeTag}
            >
              About
            </button>
          </div>
        </nav>

        <div className="tab-content mt-2" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav_ai_function"
            role="tabpanel"
            aria-labelledby="nav_ai_function_tab"
          >
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <div className="me-auto">Input</div>
                <div className="ma-auto" id="btn_clear" role="button" onClick={clearInput}>
                  Clear
                </div>
              </div>
              <div className="d-flex flex-row mt-2">
                <textarea
                  className="form-control font_12"
                  id="inputBox"
                  rows={1}
                  placeholder="Please select text then copy or CTRL-C."
                ></textarea>
              </div>
            </div>
            <div className="d-flex flex-column mt-3">
              <div className="d-flex">
                <button id="btn_translate" type="button" className="btn btn-primary col-4 font_12" onClick={translate}>
                  Translate
                </button>
                <select className="form-select ms-2 font_12" id="sel_language" defaultValue={"Traditional Chinese"}>
                  <option value="Traditional Chinese">繁體中文</option>
                  <option value="Simplified Chinese">简体中文</option>
                  <option value="English">English</option>
                  <option value="Japanese">日本語</option>
                  <option value="Korean">한국어</option>
                  <option value="Vietnamese">tiếng Việt</option>
                  <option value="Bengali">বাংলা</option>
                  <option value="Thai">ภาษาไทย</option>
                  <option value="Hindi">हिन्दी</option>
                  <option value="Spanish">Español</option>
                  <option value="French">Français</option>
                  <option value="Portuguese">Português</option>
                  <option value="German">Deutsch</option>
                  <option value="Turkish">Türkçe</option>
                  <option value="Italian">Italiano</option>
                </select>
              </div>
              <div className="d-flex flex-row mt-2">
                <button id="btn_rewrite" type="button" className="btn btn-primary col-4 font_12" onClick={rewrite}>
                  Rewrite
                </button>
                <select className="form-select ms-2 font_12" id="sel_rewrite" defaultValue={"ns"}>
                  <option value="ns">Native Speaker</option>
                  <option value="s">Shorter</option>
                  <option value="l">Longer</option>
                  <option value="tc">Typo Correction</option>
                </select>
              </div>
              <div className="d-flex flex-row mt-2">
                <button id="btn_summary" type="button" className="btn btn-primary col-4 font_12" onClick={summary}>
                  Summary
                </button>
                <select className="form-select ms-2 font_12" id="sel_summary" defaultValue={"bp"}>
                  <option value="bp">Bullet Points</option>
                  <option value="t">Text</option>
                  <option value="t">Table</option>
                </select>
              </div>
              <div className="d-flex flex-row mt-2">
                <button id="btn_ideas" type="button" className="btn btn-primary col-4 font_12" onClick={ideas}>
                  Ideas
                </button>
                <select className="form-select ms-2 font_12" id="sel_ideas" defaultValue={"Brainstorm"}>
                  <option value="Brainstorm">Brainstorm</option>
                  <option value="Breakdown">Breakdown</option>
                  <option value="Agenda">Agenda</option>
                </select>
              </div>
              <div className="d-flex flex-row mt-2">
                <button id="btn_search" type="button" className="btn btn-primary col-4 font_12" onClick={search}>
                  Search
                </button>
                <select className="form-select ms-2 font_12" id="sel_search" defaultValue={"BingSearch"}>
                  <option value="BingSearch">Bing Search</option>
                  <option value="ITServiceDeskKB">IT Service Desk KB</option>
                </select>
              </div>
            </div>
          </div>

          <div className="tab-pane fade mt-2" id="nav_ask_pdf" role="tabpanel" aria-labelledby="nav_ask_pdf_tab">
            <div className="d-flex justify-content-center">
              <div className="col-11">
                <div className="row">
                  <DropzoneAreaBase
                    dropzoneClass={`${styles.fs_11} ${styles.dropZoneHeight} dropzone_click`}
                    dropzoneParagraphClass={styles.dropZoneText}
                    showPreviewsInDropzone={false}
                    showPreviews={false}
                    showAlerts={false}
                    disableRejectionFeedback={true}
                    dropzoneText={"Drop the PDF here or Click to browse."}
                    maxFileSize={50000000}
                    filesLimit={1}
                    // acceptedFiles={["application/pdf"]}
                    onAdd={(fileObjs) => handleChange(fileObjs)}
                    fileObjects={[]}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              {/* <button type="button" className="btn btn-primary" onClick={splitAction}>
                  SplitFile
                </button> */}
              {/* <button type="button" disabled={disabledUpload} className="btn btn-primary" onClick={startUpload}>
                  Upload
                </button> */}
            </div>

            <div className="">
              <div className="col-12">
                {/* <BorderLinearProgress className="mt-2" variant="determinate" value={progress} /> */}
                <div className={styles.fs_11}>File Upload</div>
                <div>
                  <ProgressBar now={progress} label={`${progress}%`} max={100} />
                </div>
              </div>
              <div className="col-12 mt-2">
                {/* <BorderLinearProgress className="mt-2" variant="determinate" value={progress} /> */}
                <div className={styles.fs_11}>File Process</div>
                <div>
                  <ProgressBar now={nowPercentage} label={`${nowPercentage}%`} max={100} />
                </div>
              </div>
            </div>

            <div>
              {selectedFiles.map((file, index) => (
                <div className="font_12" key={index}>
                  <div className="font_12 mt-2">File Name:</div>
                  {file.file.name}
                  <div className="font_12 mt-2">File Info:</div>
                  {pdfTotalPage} pages / {Math.floor(file.file.size / 1000)}KB
                </div>
              ))}
            </div>

            {/* <div className="mt-4">
                {chunkList.map((chunk, index) => (
                  <div className="row d-flex justify-content-center" key={index}>
                    Chunk size: {chunk.chunk.size}
                  </div>
                ))}
              </div> */}
          </div>

          <div
            className="tab-pane fade mt-2"
            id="nav_one_gateway"
            role="tabpanel"
            aria-labelledby="nav_one_gateway_tab"
          >
            <OneGateway userProfile={userProfile}></OneGateway>
          </div>

          <div className="tab-pane fade mt-2" id="nav_about" role="tabpanel" aria-labelledby="nav_about_tab">
            <div className="d-flex justify-content-center">
              <button
                id="btn_func_userguide"
                type="button"
                className="btn btn-primary col-6 font_12"
                onClick={userguide}
              >
                User Guide
              </button>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <button id="btn_feedback" type="button" className="btn btn-primary col-6 font_12" onClick={feedback}>
                Feedback
              </button>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <button id="btn_roadmap" type="button" className="btn btn-primary col-6 font_12" onClick={roadmap}>
                Roadmap
              </button>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <button id="btn_disclaimer" type="button" className="btn btn-primary col-6 font_12" onClick={disclaimer}>
                Disclaimer
              </button>
            </div>
          </div>

          <div className="output-area">
            <div className="d-flex flex-column mt-4 d-none" id="gtp_editor_container">
              <div className="d-flex flex-row">
                <div className="d-flex me-auto">GPT Editor</div>
                <div className="ma-auto" id="btn_gpt_editor_apply" role="button" onClick={GPTEditor}>
                  Apply
                </div>
              </div>
              <div className="d-flex flex-row mt-2">
                <textarea
                  className="form-control font_12"
                  id="GPTinputBox"
                  rows={1}
                  placeholder="How would you like to modify the current Output?"
                ></textarea>
              </div>
            </div>

            <div className="d-flex flex-row mt-4">
              <div className="d-flex me-auto">Output</div>
              <div className="d-flex flex-row ma-auto">
                <div id="btn_gpt_editor" className="btn_gpt_editor_disable" role="button" onClick={gptEditorUI}>
                  GPT Editor
                </div>
                <div className="me-1 ms-1">|</div>
                <div id="btn_copy" role="button" onClick={copyToClipboard}>
                  Copy
                </div>
              </div>
            </div>
            <div className="d-flex flex-row mt-2 mb-2">
              <div className={`form-control output_box font_12 ${styles.outputBox}`} id="outputBox">
                {output}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mt-4">
        <div className="d-flex flex-row">
          <div className="me-auto">Version 2.0</div>
          <div className="ma-auto" id="btn_top_userguide" role="button" onClick={gotoUserguide}>
            User Guide
          </div>
          <div className="me-1 ms-1">|</div>
          <div className="ma-auto" id="btn_whats_new" role="button" onClick={gotoRoadmap}>
            What's New
          </div>
        </div>
      </div>
      <p>
        <label id="message-area"></label>
      </p>
    </div>
  );
};

export default MainPage;
